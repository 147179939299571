
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light"
          :show="dismissCountDown"
          dismissible
          fade
          variant="danger"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
        </b-alert>
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-md-center mt-4">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2 text-end">
                      เลขที่การติดต่อ:
                      <b-form-input
                        v-model="filter.ctJobCode"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2">
                      ชื่อลูกค้า:
                      <b-form-input
                        v-model="filter.nameTh"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2">
                      นามสกุลลูกค้า:
                      <b-form-input
                        v-model="filter.familyNameTh"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2">
                      ชื่อพนักงาน:
                      <b-form-input
                        v-model="filter.name"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2">
                      นามสกุลพนักงาน:
                      <b-form-input
                        v-model="filter.familyName"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-md-6 text-end">
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link :to="{ name: 'contact-job' }">
                      <button class="btn btn-primary">
                        <i class="uil-table"></i>
                        <!-- กลับสู่หน้ารายการติดต่อ -->
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <div class="app-calendar mt-3">
                <FullCalendar ref="fullCalendar" :options="calendarOptions">
                  <template v-slot:eventContent="arg">
                    <i
                      >{{ arg.event.extendedProps.nameTh }} &nbsp;{{
                        arg.event.extendedProps.familyNameTh
                      }}</i
                    >
                    <br />
                    <i>({{ check(arg.event.extendedProps.mobilePhone) }})</i>
                    <br />
                    <i
                      >พนักงาน:
                      {{ check(arg.event.extendedProps.nameCreatedBy) }}
                      {{
                        check(arg.event.extendedProps.familyNameCreatedBy)
                      }}</i
                    >
                  </template>
                </FullCalendar>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
  </Layout>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

// import btnComponent from "@/components/btnComponent.vue";
import useNetw from "@useNetw";
import btnComponent from "@/components/btnComponent.vue";
import Multiselect from "vue-multiselect";
/**
 * Calendar component
 */
//
export default {
  page: {
    title: appConfig.contactCalendar,
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    FullCalendar,
    Layout,
    PageHeader,
    Multiselect,
    btnComponent,
    // DatePicker,
  },
  data() {
    return {
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      title: appConfig.contactCalendar,
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.contactCalendar,
          active: true,
        },
      ],
      filter: {
        ctJobCode: "",
        nameTh: "",
        branchId: "",
        familyNameTh: "",
        name: "",
        familyName: "",
      },
      overlayFlag: false,
      rowEvents: [],
      calendarOptions: {
        events: [],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        eventResize: this.handleEventResize,
        datesSet: this.handleMonthChange,
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        initialEvents: this.events,
        editable: true,
        droppable: true,
        eventResizableFromStart: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
      },

      startDate: "",
      endDate: "",
      status: "",
      ctJobTypeId: "",

      branchIdSearch: "",
      localData: [],
      currentEvents: [],
      showModal: false,
      eventModal: false,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        category: "",
      },
      editevent: {
        editTitle: "",
        editcategory: "",
      },
    };
  },
  validations: {
    event: {
      title: { required },
      category: { required },
    },
  },

  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    this.localData = user.userAccessBranch;

    const localDataBranch = [];
    const localDataBranchId = [];
    // const localBranchIdItem ={};
    this.localData.forEach((item) => {
      localDataBranch.push(item), localDataBranchId.push(item.branchId);
    });
    this.localDataBranch = localDataBranch;
    this.localDataBranchId = localDataBranchId;
    // this.getData();
    //  this.handleMonthChange()
  },
  created() {
    this.getLocalData();
  },
  methods: {
    async handleMonthChange(payload) {
      this.Str = payload.startStr;
      if (this.startDate != this.Str) {
        this.startDate = payload.startStr;
        this.endDate = payload.endStr;
        await this.getData();
        // this.getData();
      }
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;

      const localDataBranch = [];
      const localDataBranchId = [];
      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchId = localDataBranchId;
      // this.handleMonthChange();
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    getData: function () {
      this.overlayFlag = true;
      // var date = new Date();
      // // // console.log(payload);
      // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      this.start = this.formatDate(this.startDate);
      this.end = this.formatDate(this.endDate);

      useNetw
        .get("api/contact-job/calendar", {
          params: {
            startDate: this.start,
            endDate: this.end,
            branchId: this.localDataBranchId,
            ctJobCode: this.filter.ctJobCode,
            nameTh: this.filter.nameTh,
            familyNameTh: this.filter.familyNameTh,
            name: this.filter.name,
            familyName: this.filter.familyName,
          },
        })
        .then((response) => {
          if (response.data.events.length === 0) {
            this.showAlert("ไม่พบข้อมูล");
             this.calendarOptions.events = ""

          } else if (response.data.events.length > 0){
            this.calendarOptions.events = response.data.events;

            const per_Page = response.data.perPage;
            this.from = response.data.from;
            this.totalRecord = response.data.total;
            this.to = response.data.to;
            this.totalPage = Math.ceil(this.totalRecord / per_Page);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit(e) {
      this.submit = true;
      const editTitle = this.editevent.editTitle;
      const editcategory = this.editevent.editcategory;

      this.edit.setProp("title", editTitle);
      this.edit.setProp("classNames", editcategory);
      this.successmsg();
      this.eventModal = false;
    },

    /**
     * Delete event
     */
    deleteEvent() {
      this.edit.remove();
      this.eventModal = false;
    },
    /**
     * Modal open for add event
     */
    check(num) {
      if (num != null) {
        return num;
      } else {
        return "-";
      }
    },
    dateClicked(info) {
      this.newEventData = info;
      this.$router.push({
        name: "add-contactCalendar",
        params: {
          dateStr: this.newEventData.dateStr,
        },
      });
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      this.editevent = this.edit.extendedProps;
      this.$router.push({
        name: "edit-contactCalendar",
        params: {
          ctJobId: btoa(this.editevent.ctJobId),
        },
      });
    },

    closeModal() {
      this.eventModal = false;
    },

    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchId;
      } else {
        this.localDataBranchId = [this.branchIdSearch.branchId];
      }

      if (this.startDate != "" && this.endDate != "") {
        this.getData();
      } else {
        Swal.fire(
          appConfig.swal.title.error,
          "กรุณาระบุวันที่",
          appConfig.swal.type.error
        );
      }
    },
  },
};
</script>
<style lang='css'>
.demo-app-sidebar-section {
  padding: 2em;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>
